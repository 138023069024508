import { IScheduleDataProps } from './../props/data';

let schedules: IScheduleDataProps[] = [];
/*[
    {
        id: "001",
        date: "2020-06-29T17:00:00.000Z",
        lessonId: "278379-29380293-938239-393801",
        teacherId: "TEACHER001",
        KEPId: "yakobussurabaya",
        lesson: {
            id: "278379-29380293-938239-393801",
            name: "Introduksi & Orientasi",
            chapter: 1,
            moduleId: "1182-29380293-938239-393894",
            module: {
                id: "1182-29380293-938239-393894",
                classId: "278379-29380293-938239-393894",
                class: {
                    id: "278379-29380293-938239-393894",
                    name: "ME",
                    order: 1
                },
                name: "Introduksi",
                active: true,
                order: 1
            },
            duration: 2
        },
        teacher: {
            id: "TEACHER001",
            name: "Leonie Probolinggo",
            email: "leonie@gmail.com",
            roles: ["Administrator", "Guru"],
            city: "Probolinggo"
        },
        KEP: {
            id: "yakobussurabaya",
            name: "KEP St. Yakobus Surabaya",
            time: "Malam",
            day: "Selasa",
            city: "Surabaya"
        }
    }
]*/;

export default schedules;