import {IFormFieldProps} from './../formProps';

const validation = {
    field: function(field:IFormFieldProps, value:any, fields:IFormFieldProps[]):(string | undefined) {
        let error:string | undefined;
        let isRequired = field.isRequired;
        if (isRequired) {
            if (field.type === 'multiselectdropdown') {
                value = value ? value : [];
                if (value.length < 1) {
                    error = "Data ini tidak boleh kosong";
                }
            } else if (field.type === 'number' || field.type === 'spinbutton') {
                if (value === undefined || value === null) {
                    error = "Data ini tidak boleh kosong";
                }
            } else {
                value = value ? value.trim() : "";
                if (value === "") {
                    error = "Data ini tidak boleh kosong"
                }
            }
        }

        if (field.type === 'repeatPassword') {
            let passwordField = fields.find((f) => {return f.type === 'password';});
            if (passwordField && passwordField.value !== value) {
                error = "Password tidak sama"
            }
        }

        if (field.textfieldConfiguration && field.type === 'textfield' && !error) {
            if (field.textfieldConfiguration.validationType === 'phone') {
                const phoneRegex = /\d{10}/;
                error = value && value.length > 0 && !phoneRegex.test(value) ? "Nomor telepon tidak valid" : error;
            } else if (field.textfieldConfiguration.validationType === 'email') {
                const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                error = value && value.length > 0 && !emailRegex.test(value) ? "Alamat email tidak valid" : error;
            }
        }

        return error;
    },
    fields: function(fields:IFormFieldProps[]):{isError:boolean, fields:IFormFieldProps[]} {
        let isError:boolean = false;
        fields.filter((field) => {return !field.isHidden;}).forEach((field) => {
            field.errorMessage = undefined;
            let errorMessage = this.field(field, field.value, fields);
            if (errorMessage && errorMessage != "") {isError = true; field.errorMessage = errorMessage;}
        });
        return {isError, fields};
    }
}

export default validation;