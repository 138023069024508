const cities:{key: string; text: string}[] = [
    {key: "Bangkalan", text: "Bangkalan"},
    {key: "Banyuwangi", text: "Banyuwangi"},
    {key: "Batu", text: "Batu"},
    {key: "Blitar", text: "Blitar"},
    {key: "Bojonegoro", text: "Bojonegoro"},
    {key: "Bondowoso", text: "Bondowoso"},
    {key: "Gresik", text: "Gresik"},
    {key: "Jember", text: "Jember"},
    {key: "Jombang", text: "Jombang"},
    {key: "Kediri", text: "Kediri"},
    {key: "Lamongan", text: "Lamongan"},
    {key: "Lumajang", text: "Lumajang"},
    {key: "Madiun", text: "Madiun"},
    {key: "Magetan", text: "Magetan"},
    {key: "Malang", text: "Malang"},
    {key: "Mojokerto", text: "Mojokerto"},
    {key: "Nganjuk", text: "Nganjuk"},
    {key: "Ngawi", text: "Ngawi"},
    {key: "Pacitan", text: "Pacitan"},
    {key: "Pamekasan", text: "Pamekasan"},
    {key: "Pasuruan", text: "Pasuruan"},
    {key: "Ponorogo", text: "Ponorogo"},
    {key: "Probolinggo", text: "Probolinggo"},
    {key: "Sampang", text: "Sampang"},
    {key: "Sidoarjo", text: "Sidoarjo"},
    {key: "Situbondo", text: "Situbondo"},
    {key: "Sumenep", text: "Sumenep"},
    {key: "Trenggalek", text: "Trenggalek"},
    {key: "Tuban", text: "Tuban"},
    {key: "Tulungagung", text: "Tulungagung"},
    {key: "Surabaya", text: "Surabaya"}
];

export default cities;