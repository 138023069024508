import {IFormFieldProps} from './../formProps';

const Metadata = {
    generate: function(fields:IFormFieldProps[]) {
        let metadata:any = {};
        fields.filter((field) => {return field.promoted;}).forEach((field) => {
            metadata[field.key] = field.value;
        });

        return metadata;
    },
    populate: async function(fields:IFormFieldProps[], data:any):Promise<IFormFieldProps[]> {
        let keys = Object.keys(data);
        keys.forEach((key) => {
            let value = data[key];
            let relatedField = fields.find((field) => {return field.key === key;});
            if (relatedField) {
                relatedField.value = value;
            }
        });

        return fields;
    }
}

export default Metadata;