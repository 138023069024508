import { ITeacherLessonConnectionProps } from './../props/data';
let teacherLessonConnection:ITeacherLessonConnectionProps[] = [
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"3cb06b8d-905b-46d5-8066-b96c5c1fea41",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393804",
       "id":"5977bd42-b526-47fd-9709-471b86cb39e1",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393801",
       "id":"aaa3b3e2-e6ab-4276-8195-ee8a8b09829a",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393802",
       "id":"76713032-682b-4db6-a0ef-981dfe9e7616",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393803",
       "id":"2f705f24-c590-45de-8823-b893f8f67fb9",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393804",
       "id":"75991a74-04ba-46a7-8122-631076ba7322",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393805",
       "id":"56796760-2b0d-4c81-9373-bf8c0055f225",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393806",
       "id":"b4a82ba0-5908-4bdd-8f96-6c7c33bb5f6e",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393807",
       "id":"fecb831f-82a4-4bc1-bc2c-7cb19a723e55",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393808",
       "id":"58b25207-7c2e-437a-b3a3-f160c55ae580",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393809",
       "id":"9da4282b-17b7-44f9-8e47-ec6bb5e65182",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393813",
       "id":"46fe373c-eb47-483c-8843-bc084a0571a1",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393811",
       "id":"57043e92-91a6-4a06-8672-4f16e6237235",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"98e5e3a5-d8fa-427d-a511-b4e68c2b24d1",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"4b4439d5-8416-4552-a4f5-fbf06d740f81",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393806",
       "id":"88907171-2217-4b8c-a7d6-163677a1b30d",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"1194c8c5-99ad-4735-9ae3-2e564a492caf",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393806",
       "id":"da225095-1954-4a19-ab8f-64d4769ea23f",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393809",
       "id":"115a553b-89b6-4242-8bcc-32a16bbb0b9c",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393801",
       "id":"58e3e331-ca81-4af2-b364-e2e794c32631",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393802",
       "id":"93611fa7-a385-4f47-bede-0732ded71fa8",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393803",
       "id":"e25d0a67-496e-414b-8c06-d141a2a0ee98",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393804",
       "id":"532b284b-e72b-4ba3-a696-531cf4c67f22",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393805",
       "id":"1fb7f053-95f0-4a43-bf63-a84778e268b1",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393806",
       "id":"66b69e8e-7712-4f39-b3e9-d95206759b9b",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393807",
       "id":"838e9a41-d29f-4599-bd5b-ac06bbf1963b",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393808",
       "id":"7363a23b-2ecc-465b-9b53-c00535ae2057",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393809",
       "id":"246c1344-e338-4eae-8532-f0f31d5b7731",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393813",
       "id":"5c698fbb-99e9-4b8b-9d52-b2b89ee32890",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393811",
       "id":"00fef254-dc2c-435e-a848-73f0fc32f3d6",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393808",
       "id":"20364fbd-fc79-4faa-9be9-839638666b4c",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393809",
       "id":"4adcefeb-f71b-4592-9277-ffcafefa18d3",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393813",
       "id":"645edc59-c56f-4f2d-89d1-119ff2e8467b",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393811",
       "id":"a8bfceea-e38f-421e-b280-6fdc885371af",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"04a0d8ac-2a52-49d7-9d91-bfa212e007eb",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393804",
       "id":"80288b2c-bd1d-4fa5-a536-cbfdd440344d",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393805",
       "id":"a74c1851-4119-45da-b67d-a98ab6e30c18",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393808",
       "id":"bef1e867-1da7-4b3e-ac0e-ce7a3386f13b",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393809",
       "id":"74889a07-527e-465b-a13c-710949a1a280",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"0bb0a34b-2797-4fea-891e-5050df951f38",
       "level":2,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"adb7c983-35d5-4ce2-a64f-0e6a28dbcfe1",
       "level":2,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393801",
       "id":"b6fd51c0-43ea-49b2-92bd-941bae8b7666",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"a92d8a58-0f5c-4161-8d02-98b4e32d322a",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393807",
       "id":"378aeeb9-55d7-4170-aad7-d75cd84f8199",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393813",
       "id":"07ceb485-dc20-46eb-8f64-e64b0b252f3d",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393811",
       "id":"a0165e92-6118-46e5-ae38-c1a9f80b1f8f",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"d70c82d8-2dac-49bc-baee-942909b18d3a",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393803",
       "id":"52d68853-5b3f-4727-9cea-a9bf27bcd714",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393804",
       "id":"a9cbeae2-55fb-4d3a-9e52-e34536ac6731",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393806",
       "id":"809ceead-a5c1-4c62-ad41-dbae69ed1297",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393807",
       "id":"23962424-2144-4ae6-b4d2-8b09f9c25da4",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393808",
       "id":"b540c29f-8e7a-45df-876f-4816a3e31fad",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393809",
       "id":"f3bc3cf6-29b4-4380-aad6-3a679a82e841",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393804",
       "id":"795aba48-4005-4b0d-b517-1d4b6476f8c9",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393813",
       "id":"e02bba79-638f-4553-a36e-a3c6d188f697",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393811",
       "id":"7d9c3152-c248-4766-9b46-11bb25ec0a47",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"9382c55f-389b-45b1-9863-8138df5a077f",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"19813e9f-44fb-4636-902a-8571a3dfc5ad",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393806",
       "id":"16709759-288d-452f-a773-e5005bed5e31",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393809",
       "id":"29fe9be5-da12-4611-89a7-9a5b2086f227",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393813",
       "id":"5bebcc95-2cc6-42bd-8eb0-f8c5500207f9",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393811",
       "id":"d54879e6-bdd8-4bef-be4b-61ee22febf4e",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393801",
       "id":"672ea77f-5202-4191-9b8f-dc38787b24ec",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393802",
       "id":"efabe609-5c77-4da8-aff1-605453ae7e28",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393803",
       "id":"91af470c-5355-4853-8a3d-d1933686f590",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393804",
       "id":"1a4bed3d-b07e-4e4f-ad65-dcdce5f7fcda",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393805",
       "id":"2555ca6b-8531-4916-aea9-a26e9d2ead2e",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393806",
       "id":"85d5a476-3f93-4765-820c-0afd16cfd89d",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393807",
       "id":"bfd3aa1b-a43d-4e91-ac6f-7f2dd2cdc74e",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393808",
       "id":"e32dcdaa-d086-4a4b-91b9-d44e98f22cfd",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393809",
       "id":"e4d3fa36-4334-40fc-b208-c7edee016481",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393813",
       "id":"f77a45cf-304e-40c1-b4a0-1f16a4972788",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393811",
       "id":"684a0762-b6a3-43fb-a7e9-3a41669194da",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393804",
       "id":"61e6124e-fc13-4a90-aa27-92a2e7d29d00",
       "level":2,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"49d3ce3f-76f9-4f58-a700-7197552b69f1",
       "level":2,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393806",
       "id":"fdadfbfb-b9a4-4ab6-b130-d5007af3fdd5",
       "level":2,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393807",
       "id":"3b9278d7-f10b-4170-8e74-d8d0c2b71a12",
       "level":2,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393801",
       "id":"d93cbfab-3251-43b2-82a7-9ffa28c1a33a",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393807",
       "id":"97f5792a-01eb-4173-bbf0-3c6afc2fc4a7",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393809",
       "id":"8b95ebb4-5bb5-489b-a2bf-3b737c38dfaf",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"b0cc905a-e723-47cc-9168-4565fc6a1a77",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393804",
       "id":"6228e793-0a3a-4c32-92bc-628a93a5ee4e",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393805",
       "id":"22d7673c-1e80-4adc-a565-c3123a04fff7",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393807",
       "id":"ded64a30-1e6d-4672-bf65-1a5d5eec1ff7",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393808",
       "id":"cdbdd18d-d46b-40ec-81b7-1a071e15f09f",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"d24e0424-f690-4489-a03d-ebde61a2f0f4",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"6b655924-3048-4e65-907f-6dedde68165b",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393806",
       "id":"2591b9a2-52bb-4719-8891-143a9523e1fd",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393813",
       "id":"33c6ea3c-054e-487b-a2b9-60ad3b6d96e0",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393811",
       "id":"a8da24e7-3988-4b70-9320-c23e329a5ba6",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"555e7170-e0e5-41c2-be53-dce818028c1c",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393806",
       "id":"d26a8f7a-feb1-48e4-8dee-f922c24ca78a",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393809",
       "id":"a8f197a1-e8ea-4b5b-ba30-7bea8f9d4f27",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393805",
       "id":"61d2db35-30b2-4a6b-b655-8fa58a9899a0",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393806",
       "id":"47af6873-789a-453c-9462-e4934493e1c6",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393808",
       "id":"14f7d45f-a458-4b5e-b3c4-a557978db7ab",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393805",
       "id":"1834d09b-3277-4648-a04d-07d5673f9531",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393806",
       "id":"c69c3026-7dbc-4873-b574-0ce2087e26ec",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393808",
       "id":"ac82f646-457e-4965-ae96-d8ec31736802",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393809",
       "id":"62651334-0b4b-4fca-8b81-86055abacebe",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393813",
       "id":"4b0d58ac-b9b8-4d2b-92bf-009eb46b0520",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393811",
       "id":"003ed759-5ed4-4cdc-af58-ff3ca8b62ab5",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393805",
       "id":"14ca0d1e-63b7-4f31-ac3d-72d569a2cd25",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393806",
       "id":"d0f5ff0c-9add-4393-a191-a035309fb27c",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393813",
       "id":"bb6a6fbd-3a08-45ed-b9dc-ec954180dae6",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393811",
       "id":"391852bc-761a-4011-b041-7a9d36631806",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"ccdd3f69-4701-4baa-afbd-5c26206c2db3",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"56b8b4fa-0071-498b-9d52-5dbe49ef6d1d",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393807",
       "id":"4eafa093-29de-45dd-9283-1660eb05a59f",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393808",
       "id":"dd096abc-8554-46ac-aae8-e05d0324c027",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393809",
       "id":"a087c13c-1e5b-4fe9-a3af-23b17f534662",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393813",
       "id":"90e8fffe-eaa0-4f1e-b228-530240d0163e",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393811",
       "id":"e0e6525d-fb9b-41c2-859c-a50190fafaea",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393801",
       "id":"d22cb6bf-6cf9-4277-b53e-2d41b8a6c974",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393804",
       "id":"12b796b4-c236-43b0-98ed-d7a2011ca86f",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393805",
       "id":"4012377a-2371-43b8-894e-abf65948fa0d",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393807",
       "id":"06d5fe95-9666-427e-8546-b8742bcc9e82",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393809",
       "id":"f25813bc-e879-4521-9bdb-94970fc1b7ea",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"bf14eb65-11a8-45b3-8884-e7abd168104c",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"6a6a0487-274f-4779-bbf6-d252d7cc0e89",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393806",
       "id":"1b0c313c-57c2-4e5e-9d58-48a78e10a973",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393809",
       "id":"2dae791e-092f-467e-8624-6c495494103f",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393805",
       "id":"a0775224-ee3a-4ac1-8bdf-3d7be6dbb24b",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393806",
       "id":"6a545b5b-338f-4a09-a9f4-0db356abbe29",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393809",
       "id":"fd9cc35e-64bd-4833-9334-87a86f6ba6eb",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393813",
       "id":"179fc1b8-1ef5-4d70-b17f-96a0ceed6ab1",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393811",
       "id":"4866b4bf-8ca1-4b6d-9de7-5d7b4ebbd2ea",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"a32a546f-3ba4-4028-9fee-c04d8ccfab47",
       "level":2,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"1bc39cdb-7eb5-4d29-a6d7-473b74ee94e8",
       "level":2,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393809",
       "id":"d7ebf268-b764-42b5-8e9f-3737cfa629b1",
       "level":2,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393813",
       "id":"d90c38e8-60fb-47c7-b18f-5184a80e7293",
       "level":2,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393811",
       "id":"1f88f5a6-14b2-4b84-b602-37a20cfde64d",
       "level":2,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"fe14c555-8310-4e45-9dca-d24106c94981",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"0288551e-e495-4802-a1e5-5c6a53fe44ba",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393809",
       "id":"2cd03a70-3b50-406f-9acf-2221eb98f961",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393813",
       "id":"88c8a3d9-4245-438b-9af4-d86d2f5e1787",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393811",
       "id":"3bc8a9ea-7235-4d15-a486-56a400dffa0e",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"815f9ba4-3897-439f-a060-ab407affa8e1",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393803",
       "id":"3692ec89-40ef-4fea-b435-bed760406c57",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393804",
       "id":"126c7220-9f13-4447-ae5d-c0ad484f9335",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393805",
       "id":"3a950c60-062a-44a6-ba73-794ed353ab18",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393807",
       "id":"b3d08ace-96e7-40a1-bd4e-f65e0911cf82",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393808",
       "id":"d6065647-c924-4c8e-994d-efee7d0ac879",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393809",
       "id":"e3e13d73-33e0-4fd6-bc0a-1a9eada3551a",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"4680abc6-31ec-41cf-9979-58240e76f266",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393804",
       "id":"39808ed4-92eb-4753-8507-518293d17448",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393805",
       "id":"d7de86f1-414a-43f1-8a21-9e186a780fda",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393807",
       "id":"825daa82-ddfc-4c46-9ba5-a9e1dfe3e5a9",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393806",
       "id":"2d643e9c-56dc-4b9d-8865-fa5a801cc933",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393809",
       "id":"cef78017-7335-44a3-b739-f3eb6b5b3d7c",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"1520b7f2-7ccc-4b81-bf6b-f2c81e02975c",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393808",
       "id":"281621d1-f433-44e8-904b-3f1e631ce14a",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393804",
       "id":"460f40fc-2791-49f9-a0de-28faf8c3f841",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393805",
       "id":"27845eb1-5b41-4cb6-b9c6-71d461bc4c60",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393804",
       "id":"0e32dd79-c64c-4ae4-b24f-3b765c55e92e",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393808",
       "id":"c0f072b3-7d27-475c-a02f-3908f2b45b0f",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393809",
       "id":"12114e31-bc17-4ef2-8883-00c359afb785",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393804",
       "id":"16f713aa-07e3-4a7b-8b1f-fc46c482f42f",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393806",
       "id":"e0de0a90-a662-4c3f-9865-c1172a98a92a",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393807",
       "id":"9d62618c-b634-4dc4-87d4-ec12b3af616a",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393803",
       "id":"c5dcd34e-796d-419b-9dac-e82b2eb8920e",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393808",
       "id":"e58311f9-2c41-4e81-91cb-9390fbfdcd4c",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"2aa9abcb-527d-4fd1-888b-6ddc3b198fab",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393805",
       "id":"3511f4ab-fd3b-4121-9feb-ba1d3d1bf867",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393806",
       "id":"4ee0806a-053d-4226-9a22-07f121e9d723",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393809",
       "id":"9914da14-bbe2-453f-8ea4-99b040b3c1ee",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393813",
       "id":"719d04d1-ec5f-4194-b4ae-fdf51182832c",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393811",
       "id":"1add6767-923b-477d-994a-b3e54bfdb0c1",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393802",
       "id":"49778429-e8cc-4365-91a5-0699bb8d7dca",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393804",
       "id":"e27218d7-7681-45cf-add5-c311258b2a6b",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393805",
       "id":"d6f017f6-5e80-4311-97b2-7b119827d79c",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393809",
       "id":"b5458f44-dd16-425b-9e1b-2b479ad9bc1c",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393813",
       "id":"721f69be-b37b-4ca3-9231-ec4bbfbd0aff",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393811",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a26",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a01",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a02",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a03",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a04",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a01",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a06",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a07",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a08",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a09",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a10",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a11",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a12",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a13",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a14",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a15",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a16",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a17",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a18",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a19",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a20",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a21",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5a22",
       "level":1,
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b01",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b02",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b03",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b04",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b01",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b06",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b07",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b08",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b09",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b10",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b11",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b12",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b13",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b14",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b15",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b16",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b17",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b18",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b19",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b20",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b21",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5b22",
       "level":1,
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c01",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c02",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c03",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c04",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c01",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c06",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c07",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c08",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c09",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c10",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c11",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c12",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c13",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c14",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c15",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c16",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c17",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c18",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c19",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c20",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c21",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5c22",
       "level":1,
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d01",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d02",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d03",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d04",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d01",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d06",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d07",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d08",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d09",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d10",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d11",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d12",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d13",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d14",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d15",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d16",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d17",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d18",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d19",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d20",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d21",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5d22",
       "level":1,
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e01",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e02",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e03",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e04",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e01",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e06",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e07",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e08",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e09",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e10",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e11",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e12",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e13",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e14",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e15",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e16",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e17",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e18",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e19",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e20",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e21",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5e22",
       "level":1,
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f01",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f02",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f03",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f04",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f01",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f06",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f07",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f08",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f09",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f10",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f11",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f12",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f13",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f14",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f15",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f16",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f17",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f18",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f19",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f20",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f21",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5f22",
       "level":1,
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g01",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g02",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g03",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g04",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g01",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g06",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g07",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g08",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g09",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g10",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g11",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g12",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g13",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g14",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g15",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g16",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g17",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g18",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g19",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g20",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g21",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5g22",
       "level":1,
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h01",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h02",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h03",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h04",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h01",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h06",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h07",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h08",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h09",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h10",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h11",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h12",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h13",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h14",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h15",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h16",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h17",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h18",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h19",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h20",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h21",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5h22",
       "level":1,
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i01",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i02",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i03",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i04",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i01",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i06",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i07",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i08",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i09",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i10",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i11",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i12",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i13",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i14",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i15",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i16",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i17",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i18",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i19",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i20",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i21",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5i22",
       "level":1,
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j01",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j02",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j03",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j04",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j01",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j06",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j07",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j08",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j09",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j10",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j11",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j12",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j13",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j14",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j15",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j16",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j17",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j18",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j19",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j20",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j21",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5j22",
       "level":1,
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k01",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k02",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k03",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k04",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k01",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k06",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k07",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k08",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k09",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k10",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k11",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k12",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k13",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k14",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k15",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k16",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k17",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k18",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k19",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k20",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k21",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5k22",
       "level":1,
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l01",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l02",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l03",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l04",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l01",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l06",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l07",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l08",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l09",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l10",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l11",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l12",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l13",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l14",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l15",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l16",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l17",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l18",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l19",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l20",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l21",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5l22",
       "level":1,
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m01",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m02",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m03",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m04",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m01",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m06",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m07",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m08",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m09",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m10",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m11",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m12",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m13",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m14",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m15",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m16",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m17",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m18",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m19",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m20",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m21",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5m22",
       "level":1,
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n01",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n02",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n03",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n04",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n01",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n06",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n07",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n08",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n09",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n10",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n11",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n12",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n13",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n14",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n15",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n16",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n17",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n18",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n19",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n20",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n21",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5n22",
       "level":2,
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o01",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o02",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o03",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o04",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o01",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o06",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o07",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o08",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o09",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o10",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o11",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o12",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o13",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o14",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o15",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o16",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o17",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o18",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o19",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o20",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o21",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5o22",
       "level":1,
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p01",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p02",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p03",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p04",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p01",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p06",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p07",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p08",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p09",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p10",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p11",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p12",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p13",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p14",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p15",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p16",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p17",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p18",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p19",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p20",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p21",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5p22",
       "level":1,
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q01",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q02",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q03",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q04",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q01",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q06",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q07",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q08",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q09",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q10",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q11",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q12",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q13",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q14",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q15",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q16",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q17",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q18",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q19",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q20",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q21",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5q22",
       "level":1,
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r01",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r02",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r03",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r04",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r01",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r06",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r07",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r08",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r09",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r10",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r11",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r12",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r13",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r14",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r15",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r16",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r17",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r18",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r19",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r20",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r21",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5r22",
       "level":1,
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s01",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s02",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s03",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s04",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s01",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s06",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s07",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s08",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s09",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s10",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s11",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s12",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s13",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s14",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s15",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s16",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s17",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s18",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s19",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s20",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s21",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5s22",
       "level":1,
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t01",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t02",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t03",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t04",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t01",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t06",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t07",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t08",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t09",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t10",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t11",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t12",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t13",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t14",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t15",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t16",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t17",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t18",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t19",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t20",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t21",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5t22",
       "level":1,
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u01",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u02",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u03",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u04",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u01",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u06",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u07",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u08",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u09",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u10",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u11",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u12",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u13",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u14",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u15",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u16",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u17",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u18",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u19",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u20",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u21",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5u22",
       "level":2,
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v01",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v02",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v03",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v04",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v01",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v06",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v07",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v08",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v09",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v10",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v11",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v12",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v13",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v14",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v15",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v16",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v17",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v18",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v19",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v20",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v21",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5v22",
       "level":1,
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w01",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w02",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w03",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w04",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w01",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w06",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w07",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w08",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w09",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w10",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w11",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w12",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w13",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w14",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w15",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w16",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w17",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w18",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w19",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w20",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w21",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5w22",
       "level":2,
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x01",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x02",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x03",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x04",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x01",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x06",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x07",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x08",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x09",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x10",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x11",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x12",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x13",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x14",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x15",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x16",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x17",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x18",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x19",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x20",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x21",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5x22",
       "level":1,
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y01",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y02",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y03",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y04",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y01",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y06",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y07",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y08",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y09",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y10",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y11",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y12",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y13",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y14",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y15",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y16",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y17",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y18",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y19",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y20",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y21",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5y22",
       "level":2,
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z01",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z02",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z03",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z04",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z01",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z06",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z07",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z08",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z09",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z10",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z11",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z12",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z13",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z14",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z15",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z16",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z17",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z18",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z19",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z20",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z21",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c5z22",
       "level":2,
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a01",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a02",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a03",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a04",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a01",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a06",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a07",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a08",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a09",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a10",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a11",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a12",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a13",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a14",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a15",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a16",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a17",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a18",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a19",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a20",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a21",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6a22",
       "level":1,
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b01",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b02",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b03",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b04",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b01",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b06",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b07",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b08",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b09",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b10",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b11",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b12",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b13",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b14",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b15",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b16",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b17",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b18",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b19",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b20",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b21",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6b22",
       "level":1,
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c01",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c02",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c03",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c04",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c01",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c06",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c07",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c08",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c09",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c10",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c11",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c12",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c13",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c14",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c15",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c16",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c17",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c18",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c19",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c20",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c21",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6c22",
       "level":1,
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d01",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d02",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d03",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d04",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d01",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d06",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d07",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d08",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d09",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d10",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d11",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d12",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d13",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d14",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d15",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d16",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d17",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d18",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d19",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d20",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d21",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6d22",
       "level":1,
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e01",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e02",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e03",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e04",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e01",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e06",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e07",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e08",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e09",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e10",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e11",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e12",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e13",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e14",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e15",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e16",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e17",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e18",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e19",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e20",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e21",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6e22",
       "level":1,
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f01",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f02",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f03",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f04",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f01",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f06",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f07",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f08",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f09",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f10",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f11",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f12",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f13",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f14",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f15",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f16",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f17",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f18",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f19",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f20",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f21",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6f22",
       "level":1,
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g01",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g02",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g03",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g04",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g01",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g06",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g07",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g08",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g09",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g10",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g11",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g12",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g13",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g14",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g15",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g16",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g17",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g18",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g19",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g20",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g21",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6g22",
       "level":1,
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h01",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h02",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h03",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h04",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h01",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h06",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h07",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h08",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h09",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h10",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h11",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h12",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h13",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h14",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h15",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h16",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h17",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h18",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h19",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h20",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h21",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6h22",
       "level":1,
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i01",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i02",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i03",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i04",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i01",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i06",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i07",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i08",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i09",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i10",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i11",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i12",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i13",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i14",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i15",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i16",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i17",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i18",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i19",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i20",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i21",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6i22",
       "level":2,
       "teacherId":"369179ef-716d-4b3f-be58-20dc7e84053c"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j01",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j02",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j03",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j04",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j01",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j06",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j07",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j08",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j09",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j10",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j11",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j12",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j13",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j14",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j15",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j16",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j17",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j18",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j19",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j20",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j21",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6j22",
       "level":2,
       "teacherId":"ccb86608-7677-46ae-95a3-c6370a147a01"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k01",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k02",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k03",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k04",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k01",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k06",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k07",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k08",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k09",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k10",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k11",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k12",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k13",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k14",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k15",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k16",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k17",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k18",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k19",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k20",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k21",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6k22",
       "level":2,
       "teacherId":"c046227e-8c16-4a6b-b4ed-12d1c1930640"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l01",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l02",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l03",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l04",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l01",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l06",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l07",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l08",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l09",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l10",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l11",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l12",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l13",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l14",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l15",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l16",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l17",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l18",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l19",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l20",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l21",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6l22",
       "level":2,
       "teacherId":"6c976178-d153-483f-9be1-2bb5a382c656"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m01",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m02",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m03",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m04",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m01",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m06",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m07",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m08",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m09",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m10",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m11",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m12",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m13",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m14",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m15",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m16",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m17",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m18",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m19",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m20",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m21",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6m22",
       "level":2,
       "teacherId":"f45169cd-28e3-4792-9ac1-9471d3a29fe9"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n01",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n02",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n03",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n04",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n01",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n06",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n07",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n08",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n09",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n10",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n11",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n12",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n13",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n14",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n15",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n16",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n17",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n18",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n19",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n20",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n21",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6n22",
       "level":2,
       "teacherId":"dc53b085-a693-4e30-8fc1-8ed7cd153e11"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o01",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o02",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o03",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o04",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o01",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o06",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o07",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o08",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o09",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o10",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o11",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o12",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o13",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o14",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o15",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o16",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o17",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o18",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o19",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o20",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o21",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6o22",
       "level":2,
       "teacherId":"12487cde-590f-4fe6-9717-a78190d6d7d1"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p01",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p02",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p03",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p04",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p01",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p06",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p07",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p08",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p09",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p10",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p11",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p12",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p13",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p14",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p15",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p16",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p17",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p18",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p19",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p20",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p21",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6p22",
       "level":2,
       "teacherId":"94e3e7ca-c15c-4d5d-bed9-2fe0980fba34"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q01",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q02",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q03",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q04",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q01",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q06",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q07",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q08",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q09",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q10",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q11",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q12",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q13",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q14",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q15",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q16",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q17",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q18",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q19",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q20",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q21",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6q22",
       "level":2,
       "teacherId":"547020bb-bb82-403e-b5e2-cb11ab0676aa"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r01",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r02",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r03",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r04",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r01",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r06",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r07",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r08",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r09",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r10",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r11",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r12",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r13",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r14",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r15",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r16",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r17",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r18",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r19",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r20",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r21",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6r22",
       "level":2,
       "teacherId":"3dc4b7af-145a-44bc-bf46-aa9bba2e4478"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s01",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s02",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s03",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s04",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s01",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s06",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s07",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s08",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s09",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s10",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s11",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s12",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s13",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s14",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s15",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s16",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s17",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s18",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s19",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s20",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s21",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6s22",
       "level":2,
       "teacherId":"26878a5b-6a20-486c-b366-a55af8cd37c7"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t01",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t02",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t03",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t04",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t01",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t06",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t07",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t08",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t09",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t10",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t11",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t12",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t13",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t14",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t15",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t16",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t17",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t18",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t19",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t20",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t21",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6t22",
       "level":2,
       "teacherId":"97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u01",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u02",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u03",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u04",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u01",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u06",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u07",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u08",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u09",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u10",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u11",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u12",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u13",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u14",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u15",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u16",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u17",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u18",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u19",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u20",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u21",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6u22",
       "level":1,
       "teacherId":"e7e55b1b-7d76-435e-ba7a-e2b96578232e"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v01",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v02",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v03",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v04",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v01",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v06",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v07",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v08",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v09",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v10",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v11",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v12",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v13",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v14",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v15",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v16",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v17",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v18",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v19",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v20",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v21",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6v22",
       "level":1,
       "teacherId":"a97d919d-e7ff-4ec1-af30-457ddc5735af"
    },
    {
       "priority":1,
       "lessonId":"278379-29380293-938239-393101",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w01",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":2,
       "lessonId":"278379-29380293-938239-393102",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w02",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":3,
       "lessonId":"278379-29380293-938239-393103",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w03",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":4,
       "lessonId":"278379-29380293-938239-393104",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w04",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":5,
       "lessonId":"278379-29380293-938239-393105",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w01",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":6,
       "lessonId":"278379-29380293-938239-393106",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w06",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":7,
       "lessonId":"278379-29380293-938239-393107",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w07",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":8,
       "lessonId":"278379-29380293-938239-393108",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w08",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":9,
       "lessonId":"278379-29380293-938239-393109",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w09",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":10,
       "lessonId":"278379-29380293-938239-393110",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w10",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":11,
       "lessonId":"278379-29380293-938239-393111",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w11",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":12,
       "lessonId":"278379-29380293-938239-393112",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w12",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":13,
       "lessonId":"278379-29380293-938239-393113",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w13",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":14,
       "lessonId":"278379-29380293-938239-393201",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w14",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":15,
       "lessonId":"278379-29380293-938239-393202",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w15",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":16,
       "lessonId":"278379-29380293-938239-393203",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w16",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":17,
       "lessonId":"278379-29380293-938239-393204",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w17",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":18,
       "lessonId":"278379-29380293-938239-393205",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w18",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":19,
       "lessonId":"278379-29380293-938239-393206",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w19",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":20,
       "lessonId":"278379-29380293-938239-393207",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w20",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":21,
       "lessonId":"278379-29380293-938239-393208",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w21",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "priority":22,
       "lessonId":"278379-29380293-938239-393209",
       "id":"7fe9ba3d-9fa6-4698-9d6c-b3f1c25c6w22",
       "level":1,
       "teacherId":"010585e2-64d9-4288-ae7d-e3aa0506f19d"
    },
    {
       "id":"23c85337-2fe5-428a-8876-7dea38e7706c",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "id":"39257d65-005f-48ee-8d41-f6ec7f28f8a8",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "id":"17b098f0-fcf0-47a6-b725-cd9b36e27922",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "id":"7b070756-38fa-43a4-a86e-842fe1974acb",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "id":"23912d36-45ce-4656-8cc2-439994d41ec5",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "id":"d1e0df80-11ee-41ae-b365-8bdadad8e359",
       "priority":6,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "id":"062b3923-3a2d-4d78-8583-3900a71a6837",
       "priority":7,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"d9ae30fc-db55-4185-8979-cc942976e3fe"
    },
    {
       "id":"47ef1d25-83f1-4ce0-88d5-eff1e97f67d5",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"4d91d369-df2b-439c-a81d-1cc49a0911e0",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"787153c5-878c-4cc5-b736-17cd83084d9a",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"4146b382-598c-4dba-a720-b52215c233f2",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"7f3e1710-ce59-4397-9ce1-9c6292569438",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"c2aa27af-728c-48ea-aa3c-e6eebe8f2cda",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"147b1445-eac4-4050-bd07-5cbdb0916c62",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"691c5dfa-067d-47bb-a9c4-0725bb6c7a01",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"7263771d-e8cf-4593-9bfa-bc8dcf3771c4",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"2c0e6467-0d53-4d42-a65f-1ca0c5555983",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"13715006-de77-4c3f-8be4-ae0437d254d6",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"7808ffc2-676c-4553-98ad-21c355718a2f",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"aba6439d-8b19-474b-809a-8709acf53bfd",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"e585a749-c081-40a5-a8ca-35d0dd35efa7",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"d8c36590-3522-40ed-89eb-150158e8a157",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"899c6826-15e8-4246-93f3-31d0a60b3569",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"bbfadce1-6d4d-47ce-99e0-3a6f585379ab",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"84648273-0686-4e10-9539-c48fb5958ef5",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"8aa80f2c-f255-4b23-8710-e3f87a9abb3c",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"124958cc-c05f-429c-91a4-849612e50166",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"ed658dee-b038-467a-b90e-399a57cdd1ec",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"9aa77695-c52a-4f91-bc72-0550ef67b641",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"20cb3923-3d7f-42e4-9b5b-5677b47a5a74",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"1ea71a9e-7b3b-4e4e-963c-4982f52a8fa9",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"7419bdf7-6156-42ca-ba8c-0ee044a79e7d",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"0f85cb72-d582-46e9-bc18-2e0130f73c9b",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"b58f10c1-a073-4390-a760-732b223615bf",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"66e09f02-39f5-4416-8c3e-010047a26547",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"59fc1572-3604-4af7-87e7-c4a6fbc049c1",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"d5e712e9-ec26-4938-a03c-f3c38ee32e11",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"ab9a2c9a-0a1b-4184-a8d2-b543f5dd6d18",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"7a481e91-cb8d-4b78-a911-79295b810caf",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"beeb5165-3456-4885-9280-25ccb034a7c5",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"ef04c826-bc54-4cdb-af76-d7f355838229",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"e853303e-3918-4758-8ba3-1079243fd5fa",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"59916a5c-d616-48f9-ad05-e1acd8a2174d",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"94c43b5a-105b-4ff7-8458-241fbc831130",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"f915cbfb-498c-481c-bba4-31ff0a55d4ef",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"9f1bf325-2922-40e9-852e-435c82c20341",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"70f5e31f-6840-49b1-a149-9a4ecf65ec5f",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"0c91447a-ae81-4d77-92e9-f6d23bbdacb6",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"cb77fc4d-8ec8-4efc-959a-1d5c00365090",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"d16b15ff-c5be-448e-8be1-625183dd2a73",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"31a18a50-f0b3-4af4-8f82-86507b7f19c4",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"78d96827-2f98-4a43-b19c-5f6cff23f00f",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"bca3a1be-7077-47bd-a4a2-1c10533707e4",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"04beb3c2-da9b-4857-b98a-1318d9ea503c",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"0cde5118-1ef4-4057-b188-f55e08d0faf2",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"1f0560a1-0862-4f61-8d48-56a6e98ced93",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"a32823d3-d5ed-48d2-bd2e-312fbc4f1abe",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"01b98231-e8e6-47b2-a52a-cc8959428af7",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"ee28d182-0fcd-4db1-b817-0c3f4b155cf7",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"62907dd2-870c-4b93-b8ac-6611c308dee4",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"a4d0dd5c-44ef-4e53-9b71-e67937b35492",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"c99219ce-89fe-41d6-8514-ffa1e390b6ac",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"f6d6f132-fd94-4d22-a785-72cd7c3b94e8",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"457216cd-3d55-4374-8709-df0cf1b99e44",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"9554091d-5768-4de8-b6ef-93d134edb775",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"3e1d5d3a-11e9-4a12-a867-825f8aefd9fb",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"57d3ae07-53ae-4bdb-ba35-909607271a9d",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"3eb34ce3-ca7e-42c7-9f87-8502b68ea720",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"d195c409-ac1d-4f35-818d-924273cc333c",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"fbb58a52-4fe8-4d7e-9a38-fcd96b3f455e",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"2b61a4f1-b1a8-4a5b-b070-2bf25940fb4c",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"c0cd344e-2000-4a79-90b8-79ecbab9546c",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0"
    },
    {
       "id":"e5793d99-7fab-489e-973f-a1e6fea2c64e",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"ca230018-12c2-49d1-b60f-a212665fb0f3",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"00890c58-1e11-4ba0-9307-3446deab63e0",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"5b90adbc-ae5f-4786-92e8-1e70770f7d74",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"1ae6ecd5-9ef5-4a88-95b6-0a954b36fd8c",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"326bdac2-33f6-4ee4-9fc2-bde20fdd7387",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"bd291084-a995-4fcf-a401-a7c1a2aa7e35",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"5cff3877-105e-4394-acbe-add4b606ae38",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"db1c201e-5dba-426c-b866-e17fa713bbad",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"f4ff7546-7d5c-4b4e-bc33-455132481cca",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"ac3f0200-e5a1-4dfa-bca1-5a77f3c89e6e",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"49b646cc-340b-4711-a5af-6a63b12f66a1",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"9a75f857-fcbf-4f43-9cb6-ab6358632906",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"2c693510-352f-4fc9-a43c-571b7c664f58",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"6595eb15-b213-49ca-929c-7a5ef8f418da",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"9b7f38b2-cc4a-4141-b3ca-b299415a626c",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"3dccae6e-1a2d-4381-99f1-349985246d64",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"962c1e13-8552-4192-95cc-4a47b27b7ff0",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"d6e8c743-7bcb-470d-8071-46e66d68e35d",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "id":"395cfa4b-2e56-47ad-b0bb-dd7cd380b6b6",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "id":"4f801146-348a-41a1-af9f-75f1ffe781a9",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "id":"412b60fc-9182-4468-b162-6d832328677c",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "id":"989c3b43-9dd1-47c0-b3b6-8809eb17be1f",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "id":"2b5a74a9-5f4a-43e7-b186-8e09e5923301",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042"
    },
    {
       "id":"b65ad9b4-ae87-4c8b-9bde-2ef45d0ecae9",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"81f82df9-1c85-4511-ab92-83cc194d7535",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"6ec93157-68da-4d11-a554-84eceff13650",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"59d0e0eb-f8f4-4488-9bce-85ac68ca050d",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"5c15b417-3dc4-4910-afb4-3c2e3412705c",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"bfcbcae2-7ebf-4c02-bd63-f5c1d4ce0121",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"5664b4e3-d7d1-4170-a77d-461af91664b5",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"91554c92-40a3-447c-98fd-080aeb988745",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"6675f4ef-918d-4188-a92d-0df6a5323957",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"a04866f2-ecb0-4ea1-9727-5cab26a17d9b",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"419b707a-3d4d-40ba-9309-60896efb4fdc",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"a5e80742-0721-4a3e-a89a-897419fa10dc",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"85f2409c-3750-4b3e-92ed-f7788f2d579e",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"f79d07d1-b62b-4391-a63f-ec9fbb5e842c",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"dfd159cd-5c46-4984-99c1-c21e99868cb2",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"bffd7006-6cd8-4631-bace-7cd9948ee695",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"a4680254-195d-4a4c-bebe-b3b8ca31d9b7",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"0ddbc914-c290-497a-ae3b-e2433b3c0e18",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"bbb318b0-4d3e-405e-91c7-d1bbee153f36",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"b7e717e3-7e2a-427d-8170-e3a10de7c4ef",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"be6117c8-d21f-42b9-bbec-c8b6d752e53f",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"b2e049ad-b1c0-4638-9f6c-bc23003cf0e1",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"080506c5-cb64-4c2d-b026-79ba8ade958c",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"afa4896e-9d04-4798-9b60-2093027179ae",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"ad14bcd4-32d3-4be4-a0a6-3214c59e85f9",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"bc79d1a9-00dd-4969-bd07-430533e65e07",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"f2f6fb2e-1e34-4ed9-ad91-275af133ff63",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"300af4e5-bb09-4113-bb53-87c58c2e56e7",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"b7653cc3-0dec-4001-adef-08942e4eb83e",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"238826c1-58dd-42fa-8b23-50b3d0504ffa",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"22296039-2164-4084-9e05-3c100e06d7f7",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"12ad2c05-07bd-459c-a8ab-be23d46f0c5d",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"108d4f82-b511-4561-bb0e-4996e150d484",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"04d30dbb-55aa-4bda-aba9-c00fed2375c1"
    },
    {
       "id":"d876551b-296a-482c-8538-0444dfafb617",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"ea98fa5a-c4ea-4157-9d6f-f01e28deed9e",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"b72ca2b2-8e6e-412e-81f3-f84af7afcd33",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"c6388088-30a1-438c-96f1-3863e33c3e27",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"c0aa8496-9cf9-47cd-8f02-f2e56b368d30",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"ffbbcfa8-91e4-4729-8071-4f267b8ff7e0",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"869a0461-7238-4606-b59d-f9a8d38e4473",
       "priority":1,
       "level":2,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "id":"53d3644e-63dc-42b9-b76a-d68375d41d2a",
       "priority":2,
       "level":2,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "id":"00f2a950-6b86-4dad-842e-66dae3d3c350",
       "priority":3,
       "level":2,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "id":"a375d90c-7e45-4806-8203-243e7b6e149b",
       "priority":4,
       "level":2,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "id":"52c84d58-aaa5-4301-80f3-66f81777b777",
       "priority":5,
       "level":2,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "id":"32c694a3-50d8-4565-8d3a-99cb8ff2621b",
       "priority":6,
       "level":2,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"7673a9ad-ea11-4641-b688-8d0b289b22b3"
    },
    {
       "id":"9a6da7df-814d-4f18-b275-5382ae5562ed",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"e2fb797b-31e6-4ae2-94d1-4496d1d87f39",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"e4832393-7ed6-4f2c-baee-454186716a08",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"959ba09f-5edd-49fd-9449-4fd8b02ed257",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"24e99022-61e1-4a3a-9896-9d77ab378d62",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"62d51dce-2a26-4ed7-959a-d34fba17eda8",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"00ed080d-17b9-4f8a-b29f-cd9645a395fb",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"ea7e9c0d-3c0d-4f1e-860a-f81225ba77fc",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"c3f4aeb9-8ac5-41f2-b75a-1a7c5eee4cc5",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"913884cb-c299-47ab-9fd6-a7b36056839c",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"c65231db-00f9-4abc-9bee-6d9ff5cbf94c",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"3c1b5095-5930-4661-a39b-edd9fbd14e30",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"7fd2329d-cf5b-4a93-8596-ed3e79d3b254",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"35805ca0-1dad-45a4-97ab-805985f9b816",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"0164bcdb-5a39-4c81-90d2-0c0b277a67de",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"76c14273-2f01-48d4-9c00-500a7b51b29f",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"4092c392-05a9-4b5e-8f21-fb61fe36a80d",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"a9091565-5f7b-4ed3-aaf9-a13f4389e2e2",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"cbf8a0e6-5534-46ae-bb11-9edb19ebf4e1",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"f325eb14-faab-46b8-9e1c-3b1efc69caf9",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"7d38ec3a-f255-438d-a920-6a4d9cd52a93",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"ad0467b8-8c9d-467b-8063-fa0e1ce3a854",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"30c8a48a-607f-49d4-877f-4d710e7db50b",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"b6d3674c-3e15-4852-83ea-976db6d3387e",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"c42ac258-3765-4ea3-bdb1-fff2340d589e",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"143d5c70-706e-4e90-a040-55224d541891",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"db6b658d-6325-410f-b228-0de4bd5280bb",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"b4bc8951-d048-41f5-999e-1720500b1688",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"1bd48cda-32ab-4ab3-b265-a654f33b0b7f",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"274dc32c-0170-458b-952f-29c12e70ff2f",
       "priority":12,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"6554739a-3dec-4cb1-9ec7-bb24d2dbbf3d",
       "priority":13,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"48f03e43-3fcf-4294-8507-3f9c2aaf6bbf",
       "priority":14,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"6c18cd7e-1c2b-43b6-aaba-e0b150f5b285"
    },
    {
       "id":"f6e765fb-b8a3-4f2f-aba6-186cf9440cd2",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "id":"ed5992b5-ea27-44d4-a97a-e48eec8f538a",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "id":"0dd2e92b-ea86-487d-98d9-95ace8338d31",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "id":"7ea5cf1f-2de9-4af2-ac75-9af9436f0dd9",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "id":"125b8506-91c1-4583-b058-ed9d34ac3709",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "id":"8bf2cdd0-ffcf-4834-825b-03adb1fd8a98",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"cd440aeb-c5e4-4951-8d98-c885a09ce724"
    },
    {
       "id":"e427cf3e-4b03-4669-a4b0-09cb1a40e054",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"45513860-acaa-4cb3-9835-3b909f1d1ba1",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"96735de5-c156-44f9-bfcf-8e5a42560b99",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"c250d8e4-c41a-4708-86dd-cfb20075a296",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"7c594664-533d-4baf-baea-00c869ec38bb",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"88598870-ba4b-4f7a-9b1d-d9eb4345518e",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"e324d432-f537-4b96-bb44-11605cd1baa2",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"5332fa99-fd41-429f-9e6c-b2364b3edde3",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"83da7d28-f463-4233-9019-7d24d1422c55",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"fe312b1f-a15d-4e08-a7b1-d116a5d14edc",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"f2923fe4-9f5a-4657-b4fa-ecd5e96b2e99",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"89e0a8f2-6a92-476f-a15a-bb7697fb7da1",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"6ae1775f-0f9b-4c94-a576-8538feea593c",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"d5334a69-fe8d-425d-98c9-dba0093c54a6",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"a7158c31-2a5e-4ed1-a8a3-77d6df399049",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"59797b58-7f1d-4a1c-9e12-5bdb7a9d9f35",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"7827123c-ce24-40e9-a8c9-2ca7ec75aa5c",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"5e56e2ba-a8f9-4f60-adbd-b80ae9fb566f",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"ea3ea706-6800-4ba0-b6ff-1dcfb21d6a95",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "id":"bd56adb6-36a8-4ab7-806a-8fa219ca27a2",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "id":"2107abd5-f836-43ad-8b80-30beb312bffd",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "id":"86ef99b1-7d64-4f73-af3f-32a8e61d4cdb",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "id":"e9add21f-8527-42bc-b1dd-12d106b2c8a2",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "id":"e0e40446-cd2e-42c6-ac58-4c8359944316",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"cee1cd3b-97c5-42fb-babf-ed9a699a2c8f"
    },
    {
       "id":"363f4621-f1fa-4589-b720-7adb6b4ea9e3",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "id":"34febe44-c51b-4780-b5ab-391ca8cdd577",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "id":"765a6ad5-ae11-4827-9787-4a7f420a832e",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "id":"ba04e28b-73ad-4737-a8e8-ca36e6915458",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "id":"0f6a6103-c16e-4ccf-9d7c-5f1adcee959b",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "id":"798c315f-23b3-4d14-ae6c-69603875e100",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"f6884e40-b454-420d-aacb-2d1db06dc0e0"
    },
    {
       "id":"f420a19f-db22-4b6a-9252-80b4c8910535",
       "priority":1,
       "level":2,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "id":"08c07c16-5b9d-47e4-89ee-aaf34c8dfa3f",
       "priority":2,
       "level":2,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "id":"af63d464-cffb-441d-9e25-5a3ea99ac956",
       "priority":3,
       "level":2,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "id":"93bc1cd0-559d-44c8-9f7a-d60ff7c43b07",
       "priority":4,
       "level":2,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "id":"50b8f652-af7d-4c8f-b527-9106dd112ebb",
       "priority":5,
       "level":2,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "id":"74832195-5950-463d-b6d9-cb9ec2a6dbd1",
       "priority":6,
       "level":2,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"239d0842-30ef-40f0-a657-e71804ae8d7d"
    },
    {
       "id":"ad1faa5c-aa1c-47df-ac9a-90e7941884a9",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "id":"8ac3cc78-7f50-476c-83ed-e5676f615cf5",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "id":"ca9be87e-8eb1-4849-aed4-a3e05269bd72",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "id":"b58fa679-64c3-4bc0-b5cf-1ba12bd0ce97",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "id":"b0dd6448-fdfb-485a-a130-d46e76d4aed1",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "id":"d5c862ec-b23d-4840-a76c-bc6cf4d5a93c",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263"
    },
    {
       "id":"7e7e997f-d144-4199-84e8-5fd35368b095",
       "priority":1,
       "level":2,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "id":"b05e7abe-0f2a-4287-9fdf-a3647c40e09e",
       "priority":2,
       "level":2,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "id":"e345c922-46d5-4093-afdc-70a63b9edc63",
       "priority":3,
       "level":2,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "id":"cd69001b-f2c6-499a-b40b-deb120cbcc14",
       "priority":4,
       "level":2,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "id":"f6114489-d75c-4f8d-bd4d-9f3dc21e22bc",
       "priority":5,
       "level":2,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "id":"c4981ced-77ec-4185-bebe-8a17228dda4f",
       "priority":6,
       "level":2,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"d2317d45-3766-4530-98ee-ac1541276878"
    },
    {
       "id":"63048a4d-2da4-4810-9ec1-e90425ef9497",
       "priority":1,
       "level":2,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "id":"5671ff06-fd9a-4fdb-ae64-c9209a8028b4",
       "priority":2,
       "level":2,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "id":"640aa4ef-8ed9-47ef-8ddb-cc4aace9ab18",
       "priority":3,
       "level":2,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "id":"036aaa88-22cf-4e64-a52f-7e68947c809e",
       "priority":4,
       "level":2,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "id":"f8196ecc-1217-4836-82df-71742bd46c14",
       "priority":5,
       "level":2,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "id":"5873e26f-9114-45a2-9ee6-cd1aa7fa04c6",
       "priority":6,
       "level":2,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"618b39d4-2280-42a2-ba9d-83b31b6e138b"
    },
    {
       "id":"1b9eda6b-950b-41a7-8089-8976cda46923",
       "priority":1,
       "level":2,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "id":"16a0d968-6225-4c19-937c-cdc3b5cc5365",
       "priority":2,
       "level":2,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "id":"4d5741d9-43bb-497c-945d-f313bbc70ebc",
       "priority":3,
       "level":2,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "id":"a908e732-8563-4c79-a8ab-3ac52c038cab",
       "priority":4,
       "level":2,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "id":"e291a8dc-45f6-4e0b-8d7f-942cca4ff2af",
       "priority":5,
       "level":2,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "id":"53526c26-c0df-4ece-9915-f0edc7a43869",
       "priority":6,
       "level":2,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"3ec3a8bc-e546-414f-8343-d95defa33737"
    },
    {
       "id":"473b25bf-1af9-4a73-b59c-2337900c4952",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"d8a0a75a-4cd1-46f0-b651-f1b6eee83a89",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"f816b920-7dd7-4200-9988-aa11d0e1d93a",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"3dccad68-6f3e-46fd-a9ac-09389fa90482",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"c702043c-bef5-4c73-9a6f-85ebaa7d8baa",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"a1ed0603-09fd-4efc-aa2b-ebea986c56e9",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"b87b88d2-6d7c-4d5e-84fe-fd4a74d72920",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"f6571770-f6da-4203-852c-36eec151d483",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"a0df5319-eaf3-45cd-b3d9-76665cf1513b",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"3bd5085e-0182-4f3d-a88d-e0fc21df1a4a",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"09f12415-9b0a-4346-aa0a-3bc94029c97f",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"3b3528b3-8882-4e23-ac6d-7731896dbc23",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"71c24da3-de52-46b7-a406-25680a38d8fb",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"5b5f117a-8480-40d5-8916-9da51cea695a",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"36e0d252-0872-4a8d-89e5-7e197959f735",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"be369326-e81b-41a9-a4c7-9c77d341364f",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"feab6eac-cf83-43e1-8a29-3f1fac16995f",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"7e19da96-17a1-4fd3-8519-ac32a37b05e7",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"56806d46-ef22-4a88-9146-8913c2c3cdef",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"bedd42de-a8c3-4f1a-8eae-4693f311b576",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"38946e56-5af9-4c4e-86d0-75c45d31e7bd",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"615864fc-efba-4c9a-b1c8-e5b52e85fc72",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"1db2aac6-5807-4480-9550-7ab4b24e83af",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"46379581-fc44-42b7-b588-5ae956465493",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"a7e47eda-8c4c-47f7-971f-bd18045fba64",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"cc80773c-1762-4ae9-82fa-d4e1e67fd2dd",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"19facdc0-4a14-4e30-bd44-bce27a914fa9",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"fd787ab2-7dc2-4b57-a881-e44b0e9203ab",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"973ee209-8607-45cf-a78e-3eae958dadeb",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"952876aa-3916-49d6-b1b3-f1a7cea743b9",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"29a00ba5-6d67-4c85-b9b9-92098f3d5066",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"7d69eef6-270c-4e75-8e41-d00a635bf4c1",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"74f29df9-5fd0-4810-83ce-de12cbb9870b",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"69683277-8d7b-49d9-aea4-c1e4d859b6d1",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"7966aeac-ea05-44a8-a413-ef1b89546d49",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"508a288d-7e65-4f26-9c6a-d2ed698ba27f",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"ff7179bf-8eed-4005-a47f-8566dd7116f6",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"b3f87e7c-dc6a-42f2-8cc6-bbc56a5ee176",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"95de2f27-a872-4707-b82b-c9913b6fef71",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"308fcd69-856e-42e4-92b0-3e7462f20508",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"86c65966-cec8-4b21-abf1-6689cbdf3fba",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"a22e100b-353b-4ac1-8c6a-c91ed86b1319",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"86552179-656c-45df-90da-f52c5f8c87ce",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"f1aa51da-cf2d-4f6d-8d2f-a63af90467aa",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"ebd1985f-1dc8-4560-9e89-ae31a8cb6787",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"7c30b326-aba4-4ccf-8058-deab0c2ea4c3",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"11013d6d-cc0e-49a0-bb82-a6f4202bd851",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"8696f530-0335-4161-bf8f-a43fa56f06df",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"2a34d469-f1a6-4153-929f-6b177d8cb52f",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"74373381-d24a-4e9d-8626-64a337e449b4",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"d5225004-70b3-4051-9142-cb959f9e0aa7",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"4db6ba67-c44d-4dd0-9874-ba8195f847a1",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"f72d7bda-9d2a-4c88-a15f-43a49de26565",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"db0f946c-36e3-44d3-8f71-c6d72671b152",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"da6702b9-6291-4e4c-8752-ba4bf006c4bc",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"01034600-9ace-4c34-9410-af4eb630eeb4",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"fabce105-57bc-44f6-be51-cf4afbefa093",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"0327b413-214d-4d88-9996-4452331595f7",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"59578c92-c1b9-4517-9a9e-92d394cbd2d8",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"c235e4d5-8852-48c0-bee1-56c11e065425",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"8a48c6ad-a18e-44f0-98ec-2925c5b6d921",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"9bd226d3-6e24-4472-a2c4-80087fb78f50",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"3db42fc3-b0af-4fca-819d-bfc665c48b45",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"e2748512-8785-45f0-adf1-3b8b1c467fe2",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"de81441d-bea9-47f4-8ea8-7fa1c0b2177b",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"557c7c3e-79f6-458b-b63c-dbc6be3fd276",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"5dc09aac-50cb-4092-80dc-5d470338b196",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"4e46135d-42df-460a-9b86-0308b4172d36",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"26f24028-d404-4cc4-8cb7-9b8959cd78a6",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"96accfb8-11f8-48f8-8582-011d07aa7ced",
       "priority":16,
       "level":1,
       "lessonId":"3617c25c-ba48-4f77-9464-40ad81ecee41",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"a51d3b58-ba36-4ca8-9882-3836b429a182",
       "priority":17,
       "level":1,
       "lessonId":"f1695c76-a7be-4d8c-a8fa-1022849835b2",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"d8abf327-c142-4dec-9b46-97569897b8b2",
       "priority":18,
       "level":1,
       "lessonId":"6cd19320-2462-4e78-9b10-06435c92300e",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"9876defc-f678-4d13-8633-4da238d513b3",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "id":"8a234bfe-2718-444d-9095-575c6da9d5d4",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "id":"19e79e6d-3657-4a77-8744-e2a399b3f619",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "id":"85604d82-f104-4435-ac9a-724b4e665c7f",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "id":"1f2f4c39-9631-41d2-997c-99c6e77ce5c1",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "id":"ace4c30c-5994-472d-aeee-bc2f6aaf1c79",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"2b3ad392-a85b-4f8d-9ff4-0c98a831b297"
    },
    {
       "id":"6fd1a731-8f5f-4817-bc54-66e24c20bff9",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "id":"3ecc08e4-e7e5-4ade-8f32-147ccf033009",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "id":"59c43d9e-370d-4f54-a699-acb0c27fd738",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "id":"10dc0a62-b9d2-424a-ab36-a721dce2de12",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "id":"fccd85c3-328f-4930-9682-d2c2cca1c2ec",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "id":"6227dc68-eda2-4b99-b77c-620e7bca8d6b",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"98a83131-585f-4063-a699-11b3ade8c9f9"
    },
    {
       "id":"96b50b50-36f7-4d48-8b55-0a08adcf7c88",
       "priority":1,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "id":"d3022fdf-5923-4046-a041-c39b4df1eb4d",
       "priority":2,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "id":"33939b8d-5f1d-47b5-9c9b-e6f5344ad3bb",
       "priority":3,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "id":"de5a849a-774e-42c9-81f8-0278f88d66ab",
       "priority":4,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "id":"9977a1b7-a6ea-4db6-b116-97531b532f44",
       "priority":5,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "id":"5b066b8f-792a-44b7-b4db-26c9485a9381",
       "priority":6,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"87f0ee98-0243-4bef-a432-47185f3aa768"
    },
    {
       "id":"d82b2d79-b459-4d2e-a171-f4ad71f6399a",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"d6e1be64-fe44-4a11-9dcb-dd3ba2657835",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"41d3ec86-f988-4abd-937f-3ac37cf34f1d",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"2752b366-604a-4d50-9e6a-7c56ac2ef42d",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"45639f5c-ec78-48e6-8132-7dbe954eb2c9",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"5a89afd0-f232-4d8b-84f8-64ec1107dfce",
       "priority":6,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"8470a4ef-15bd-47ac-8a1a-e1c39cf890b6",
       "priority":7,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"49caebb4-45a9-4512-b3cf-2185f39a94d4",
       "priority":8,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"70e38003-6e21-4be9-b5d3-b04c34961bbd",
       "priority":9,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"cbbbf104-c4a9-411a-a5d9-85ec77c2ba23",
       "priority":10,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"5d284ee5-b221-4144-a51c-389e6a2fa596",
       "priority":11,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"63164783-cbf5-4bfc-80ca-210b1cceba50"
    },
    {
       "id":"2c6130da-4aa2-4dd6-a0c4-872f504e1267",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393501",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"032a0674-9bf4-4ef9-b5aa-cc6bdc59b8d6",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-393502",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"65a3972e-2a5f-413c-bcb4-04327c80e17a",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393503",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"d0c6414c-4ebb-404c-90ee-fa514d6417f1",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393504",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"9977ca77-16f8-4d78-b05e-7de48d0be45c",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393505",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"05445833-e078-4057-bcf3-19332fc429e7",
       "priority":6,
       "level":1,
       "lessonId":"278379-29380293-938239-393506",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"0a68a6a8-9859-40e2-bf6d-aa38ea3de2b9",
       "priority":7,
       "level":1,
       "lessonId":"278379-29380293-938239-393507",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"60b40faf-67c8-435a-893a-4a247d5d5f77",
       "priority":8,
       "level":1,
       "lessonId":"278379-29380293-938239-393508",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"cd134763-3624-4ee8-a234-18193275c742",
       "priority":9,
       "level":1,
       "lessonId":"278379-29380293-938239-393509",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"3f814458-9dbc-493c-ab17-516c6405fd17",
       "priority":10,
       "level":1,
       "lessonId":"f44563e0-7c9b-4d82-aa0b-1273e257cab0",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"6a1ffd51-5f1b-414a-b473-c0f22bc12b0e",
       "priority":11,
       "level":1,
       "lessonId":"c7756f43-4881-4909-80f7-6037d38a715d",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"30728425-e3fa-47cc-9b5f-9ffcc634c1fc",
       "priority":12,
       "level":1,
       "lessonId":"db4fe641-44b6-450c-917f-21c1e644bb42",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"b041f1da-2bd5-4519-8f05-34dcc3c49cb0",
       "priority":13,
       "level":1,
       "lessonId":"cb7bcb03-9ef9-4217-9b44-7958e3aabafa",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"02ce56f0-bd34-4e08-aa2a-300f77f6679e",
       "priority":14,
       "level":1,
       "lessonId":"c23d7579-4150-4b64-8a67-2eee8ecf0a82",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"99a4cb61-929e-4576-8ae0-0ad9ee984e89",
       "priority":15,
       "level":1,
       "lessonId":"a503160f-81e4-4959-9bb7-66a858912001",
       "teacherId":"18c5b4ad-a652-4ed6-bf31-4eee1d6f5909"
    },
    {
       "id":"b5a58bc5-0518-4977-9b5a-b4e475128978",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"7d56f225-68d0-4775-9641-6b35ff6b7cd5",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"9af9dc2d-ca95-42b9-b249-182e583f1966",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"f3672de0-e1a8-4d86-8545-38c78dd527ac",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"7b1a63f1-e263-4669-bd06-da40c8a42fac",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"f9ac7dab-53b2-4a5f-9846-a17080be62bd"
    },
    {
       "id":"a7855129-d6c3-4ad5-8c8a-8970794c281a",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"5a416d1e-e7bb-4ebf-abc0-f422899a4a17",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"2d834eca-78b2-4571-bce9-3a1e9e889a6a",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"ad39823a-6497-4418-8b86-4f6267b3429f",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"3cce4f0f-948a-4c06-9656-7bda63b8f281",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"324d5861-ed90-4246-912f-2ff91812b2ee"
    },
    {
       "id":"e75ed945-6c97-46f1-b5d5-2419894e3246",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"b28a2d3e-f3df-4fe4-aaba-fa80186a7d16",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"7c7ad45b-a996-4f72-9500-40d606405cff",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"a3519b5e-9b1b-4432-b8b3-18402cc1b480",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"e0d4cb92-a07b-44a9-a2c0-403bfe7b6f0b",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf"
    },
    {
       "id":"4568f3d3-8613-435b-a68d-1de2062f77b4",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"0377cbda-9926-4e11-8436-276e2553e78f",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"1dfc85c4-8a09-4071-96b3-6711d91e98f9",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"fb3962b6-a18c-4ac1-bf77-d5fd00343746",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"4f8ff259-f0dc-4b00-9c21-c95379db67d8",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"f42c9a79-f28e-48ca-b3ce-4ac0f60e3257"
    },
    {
       "id":"073b1452-8f4b-4081-97c9-73ca428bb3ff",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"03c72395-cd4c-4c33-b66e-058e0f501549",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"373495b3-959a-4f8b-9fe6-708cbfc0b1a5",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"36044fef-9f41-439d-9eda-6b02da5ecc1e",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"21a2dd77-418c-451b-847a-e61faaa11c72",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"2238409c-6346-45a3-8e4e-31c2dbe0cf41"
    },
    {
       "id":"626493e0-48ed-477a-a860-604f84dd6c0c",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"e346dede-6833-4b07-a447-63833c1d9fea",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"fff0a82e-68a2-49bb-9dde-bbef9ea6a0dc",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"9d36dafd-1865-45c9-be7c-0e1d2a64ff8f",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"779fb4d5-8afc-4a1a-8032-979a4a5061b3",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"135ecf91-b9e6-4128-bf2d-067d8c948c46"
    },
    {
       "id":"c07d60a3-7563-4b74-8812-d5c71c08e408",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"3bfa6626-b42e-4cc7-b187-b07c23206970",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"aee26df5-ce12-450a-bf9c-4224e267b2bf",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"52eb9f89-5bde-4d87-88e7-3d6ad35bd2a1",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"9c4f1749-f8e6-4a61-b827-a2ef70988b16",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"09642a68-33a4-4284-ac3b-afac7dd953a4"
    },
    {
       "id":"d754bdd0-509d-42c5-9d94-8aa6f00c54bd",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"b60cd6df-cae5-4646-bd84-265116b38b94",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"d39a9a37-c3af-4c26-bdd6-5fa8dab6d6b1",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"d1232cc7-1c3c-4c3f-8983-14c177363757",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"afe17b59-1ffd-448f-93e6-dbaae8bde484",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"d9e309fe-db6a-46ea-8280-644e81d04c3c"
    },
    {
       "id":"682da0c2-f369-4c31-91cc-908b0200fece",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"ef340b26-5149-499f-82f8-570a4500c88b",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"eaf23ed9-8e7d-4b64-b311-0b887bf280b9",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"da8e66f2-6c1f-4209-9321-09e32c75e687",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"ab689665-7706-465e-9e60-77363f2fc66e",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"bd879068-d79b-490e-b26e-d4909cc99948"
    },
    {
       "id":"a8fc76ed-6dcb-4e0e-9dee-45f6fe79fc82",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"808300a2-2464-4331-9476-848977f76bf3",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"0e1241d9-c796-4422-a0e8-1c8e639f952b",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"ea9971c6-59b0-4492-863d-7c14dbcde632",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"1d6a7f5e-0296-43f4-8522-9f252e3117ed",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"fb20b853-0d87-429b-b685-0c1bb615611d"
    },
    {
       "id":"bb1755b9-d05a-40f5-bb09-e68008dbc575",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"a1467c56-96c5-4c3e-8a7a-97ed95a8aaed",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"abce4ce7-c8dc-4a47-8b59-bd26f35cdb0b",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"c978e74e-ebee-4e3b-a433-20ba31302ed1",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"5c9f13d3-8b4e-4d91-8642-b736b4c72c43",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"3620a4c3-3d17-45f1-a230-937a71464b71"
    },
    {
       "id":"a6f22df8-4324-4385-83a0-ba6882e35b4f",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"00389794-e227-4749-a95b-659d05ab31f9",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"5c7b8d6b-7aa8-4416-a4d4-40092bc682a9",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"4a9bce47-1255-40df-bcbb-f2a8f6001645",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"fe2f41a9-0b40-455f-99e9-b2a63b70bb21",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"89ee8d77-81c7-4c6f-bff8-68d7a06596e3"
    },
    {
       "id":"ed0574de-a977-4e23-a253-3dae72705e2c",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"c28f78e1-5f1b-4428-bf36-53c0a1b6aa0c",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"05b3b34e-fb93-4b74-82a4-2e661600b4f7",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"12d85de5-d608-4a7f-89d5-a6f8fd6ca443",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"76b27802-fc40-4463-bbc9-e17bf2ff0f0d",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"f2a29d42-f5a7-4ada-b38f-0bc4e94419a6"
    },
    {
       "id":"b23137dc-e483-47b5-8cf9-24c736d6c82b",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"b22da738-f4d4-4cf1-9911-dfd5af2f10dc",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"0098f1e7-7bcb-4d5d-b25f-fd7d0b14d67b",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"eb3e8133-07d8-4585-bc3a-c453e2965aae",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"6b942b21-72c4-43d8-9257-a054ef055251",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"238fdf5a-225a-4cd9-8a6c-5836d7ded890"
    },
    {
       "id":"44f3ff13-ab2c-47ae-8769-fcb67949a4db",
       "priority":1,
       "level":1,
       "lessonId":"278379-29380293-938239-393402",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"1e4d7f7d-b7fe-471f-8076-25571e23f34f",
       "priority":2,
       "level":1,
       "lessonId":"278379-29380293-938239-399002",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"e25d4772-60c7-47b9-bb07-d13b93f1543e",
       "priority":3,
       "level":1,
       "lessonId":"278379-29380293-938239-393403",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"de7a3293-66df-45d7-9026-342048b8b384",
       "priority":4,
       "level":1,
       "lessonId":"278379-29380293-938239-393404",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    },
    {
       "id":"ff9c17f9-39f7-4425-bf41-7142390422ea",
       "priority":5,
       "level":1,
       "lessonId":"278379-29380293-938239-393405",
       "teacherId":"f40a286d-93dd-4d13-8e77-36b36255e684"
    }
 ];

export default teacherLessonConnection;